import { graphql } from "gatsby";
import React from "react";
import NotFound from 'pages/404';

import Image from "components/Image";
import Link from 'components/link';
import Content from "components/templates/Content";
import Layout from 'components/templates/Layout';
import PhotoGallery from "components/templates/global/PhotoGallery";

import useLiveData from 'hooks/useLiveData';

const SimpleViewListingGetHandler = props => {
	let photos = []
	const { edges: nodes } = props.data.allSimpleviewListing;
	const listings = nodes.map(({ node }) => node);
	let listing = listings[0];

	const { statusText: providerStatusText, status: providerStatus, response: providerResponse, visibilityChange } = useLiveData({
		type: 'SimpleviewListing',
		staticData: props.data,
		apiParams: { uri: props.location.pathname, approved: true, archived: false, deleted: false }
	});

	if (providerStatus < 2) return null; // This prevents any flash of content until the API is loaded -- but if the API takes a while to respond the effect would be the page loads slower. You could also use state to reveal content with a transition
	if ((providerResponse.data.length > 0 || !!listing !== false) && providerStatusText === 'LOADED') {
		listing = providerResponse.data[0];
	}
	if (providerResponse && visibilityChange) listing = providerResponse.data[0];
	if (!!listing === false && providerStatus >= 2) return <NotFound />;

	photos = listing?.photoGallery?.map((photo, index) => ({
		defaultSrc: photo,
		src: `${photo}${index % 2 === 0 ? "?width=400&height=400" : ""}`,
		width: index % 2 === 0 ? 400 : 1200,
		height: index % 2 === 0 ? 400 : 900,
		srcSet: [
			{ src: `${index % 2 === 0 ? `${photo}` : `${photo}?width=400&height=400`}`, width: index % 2 === 0 ? 1200 : 400, height: index % 2 === 0 ? 900 : 400 },
			{ src: photo, width: 1200, height: 900 },
		]
	})) || [];

	if (!!listing?.url && !/https?:\/\//i.test(listing?.url)) {
		listing.url = 'http://' + listing?.url;
	}

	return (
		<Layout headerBackgroundImage={listing?.photo}>
			<Content
				headlineSchema="light"
				headlineAlignment="center"
				headlineTitle={listing?.companyName}
				headerPostContent={
					<>
						<div className="grid-x grid-padding-x align-center">
							<div className="cell small-12 medium-4">
								<div className="image-wrapper">
									<Image src={listing?.logoPhoto ? listing?.logoPhoto : listing?.photo} alt={`${listing?.companyName} Logo`} />
								</div>
							</div>
							<div className="cell small-12 medium-7">
								<div className="description-wrapper">
									<h3 className="description">Description</h3>
									{listing?.description}
								</div>
							</div>
							<div className="cell small-12 medium-11 details-wrapper">
								<div className="grid-x grid-padding-x">
									<div className="cell medium-6">
										<div className="details">
											<h4>Address</h4>
											{listing?.address1} {listing?.address2} <br /> {listing?.city}, {listing?.state} {listing?.zip}
											{(listing?.email || listing?.phone) && <h4 className="contact-header">Contact</h4>}
											{listing?.email && <><a href={`mailto:${listing?.email}`}>{listing?.email}</a><br /></>}
											{listing?.phone && <a href={`tel:${listing?.phone}`}>{listing?.phone}</a>}
										</div>
										{listing?.url && <Link className="button blue" to={listing?.url}>Visit Website</Link>}
									</div>
									<div className="gallery cell medium-6">
										{!!photos.length && <PhotoGallery photos={photos} />}
									</div>
								</div>
							</div>
						</div>
					</>
				}
			/>
		</Layout>
	)
}

export default SimpleViewListingGetHandler;

export { Head } from 'components/templates/Head';

export const query = graphql`
  query simpleviewGetListing ($permalink: String) {
    allSimpleviewListing(
      filter: {approved: {eq: true}, archived: {eq: false}, deleted: {eq: false}, permalink: {eq: $permalink}}
    ) {
		edges {
			node {
			  photo
			  companyName
			  description
			  logoPhoto
			  address1
			  address2
			  city
			  state
			  zip
			  email
			  phone
			  url
			  photoGallery
			}
		}
    }
  }
`